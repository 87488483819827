import PropTypes from "prop-types";
import { isBrowser } from "../../../context/helpers";
import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";

function AggregateOfferMeta({ product, currency }) {
  function getNextMonthDate() {
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());

    const year = nextMonth.getFullYear();
    const month = (nextMonth.getMonth() + 1).toString().padStart(2, "0");
    const day = nextMonth.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <div itemScope itemProp="offers" itemType="https://schema.org/AggregateOffer">
      <meta itemProp="lowPrice" content={product.variants[0].price} />
      <meta itemProp="highPrice" content={product.variants[0].price} />
      <meta itemProp="priceCurrency" content={currency} />
      <meta itemProp="offerCount" content={product.variants.length} />
      {product.variants.map((v) => (
        <div
          key={`offers_variant_${v.sku}`}
          itemProp="offers"
          itemType="https://schema.org/Offer"
          itemScope
        >
          <meta itemProp="sku" content={v.sku} />
          <meta
            itemProp="availability"
            content={
              v.availableForSale ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
            }
          />
          <meta itemProp="price" content={v.price} />
          <meta itemProp="priceCurrency" content={currency} />
          <meta itemProp="priceValidUntil" content={getNextMonthDate()} />
          <meta
            itemProp="url"
            content={
              isBrowser
                ? `https://www.analuisa.com${window.location.pathname}`
                : `https://www.analuisa.com/products/${product.handle}`
            }
          />
          <meta
            itemProp="itemCondition"
            itemType="https://schema.org/itemCondition"
            content="https://schema.org/NewCondition"
          />
        </div>
      ))}
    </div>
  );
}

AggregateOfferMeta.propTypes = {
  product: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

export default withALErrorBoundary({
  name: "AggregateOfferMeta",
  priority: "P3",
})(AggregateOfferMeta);
