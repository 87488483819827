import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import bannedCollectionPages from "../../data/seo_banned_plps.json";
import { Link } from "../al_components/ALLink";

import * as Styles from "./productsInCollection/styles.module.scss";

const COLLECTION_LIMIT = 15;
function ProductsInCollection({ children, collections }) {
  const filteredCollections = collections
    .filter((obj) => !bannedCollectionPages.includes(obj.handle))
    .slice(0, COLLECTION_LIMIT);
  return (
    filteredCollections &&
    filteredCollections.length > 0 && (
      <div className={`${Styles.productsContainer}`}>
        {children}
        <div className={`${Styles.productsContent} microtext`}>
          This product is included in those collections:&nbsp;
          {filteredCollections.map((c, i) => (
            <Link
              className={Styles.productsContainerLink}
              key={`products_collection_${c?.handle}`}
              to={`/collections/${c?.handle}/`}
            >
              {c?.title}
              {i < filteredCollections.length - 1 ? (
                <span dangerouslySetInnerHTML={{ __html: "&nbsp;-&nbsp;" }} />
              ) : (
                ""
              )}
            </Link>
          ))}
        </div>
      </div>
    )
  );
}

ProductsInCollection.propTypes = {
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      handle: PropTypes.string,
    })
  ).isRequired,
};

export default withALErrorBoundary({
  name: "ProductsInCollection",
  priority: "P3",
})(ProductsInCollection);
