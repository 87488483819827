import { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { PropTypes } from "prop-types";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import { isBrowser } from "../../context/helpers";
import { useTracking } from "../../context/Tracking";

import EmailIcon from "../icons/svgs/email.svg";
import SmsIcon from "../icons/svgs/sms-chat.svg";
import CallIcon from "../icons/svgs/phone.svg";
import IconClose from "../icons/svgs/close-md.svg";

import DropHintDesktop from "../../images/drop-hint/drop-a-hint-desktop.jpg";
import DropHintMobile from "../../images/drop-hint/drop-a-hint-mobile.jpg";

import "./dropHintPopup/styles.scss";

const SOCIAL_PLATFORMS = {
  WHATSAPP: "Whatsapp",
  EMAIL: "Email",
  SMS: "Sms",
};

function DropHintPopup({ onClose }) {
  const dict = useLocalizedSentenceDict();
  const { trackDropHintIconClick } = useTracking();

  const [whatsappUrl, setWhatsappUrl] = useState("");
  const [emailUrl, setEmailUrl] = useState("");
  const [smsUrl, setSmsUrl] = useState("");

  useEffect(() => {
    if (isBrowser) {
      const url = window.location.href;
      const doubleNewLine = "%0D%0A%0D%0A";
      const message = `${dict.get("Hello!")}${doubleNewLine}${dict.get(
        "Sending a subtle hint that this has caught my eye! 💝"
      )}${doubleNewLine}${url}`;

      // Build SMS URL
      let smsLink = `sms:&body=${message}`;
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("android") > -1) {
        smsLink = null;
      }

      // Build WHATSAPP URL
      setWhatsappUrl(`https://api.whatsapp.com/send?text=${message}`);

      // Build EMAIL URL
      setEmailUrl(`mailto:?body=${message}`);

      setSmsUrl(smsLink);
    }
  }, []);

  const onIconClick = (socialIcon) => {
    if (isBrowser) {
      trackDropHintIconClick({ pageUrl: window.location.href, socialIcon });
    }
  };

  const dropHintImage = useResponsiveWithHydrationFix(
    <img src={DropHintMobile} alt="Drop a Hint" />,
    <img src={DropHintDesktop} alt="Drop a Hint" />
  );

  return (
    <Popup closeOnDocumentClick open onClose={onClose} modal nested className="drop-hint-popup">
      <button type="button" className="drop-hint-popup__close" onClick={onClose}>
        <IconClose width="40" stroke="#2D2927" />
      </button>
      <div className="drop-hint-popup__container">
        {dropHintImage}
        <div className="drop-hint-popup__share-icons">
          <a
            href={whatsappUrl}
            title="whatsapp"
            onClick={() => onIconClick(SOCIAL_PLATFORMS.WHATSAPP)}
          >
            <CallIcon
              className="drop-hint-popup__share-icons__call-icon"
              width="24"
              fill="none"
              stroke="#2D2927"
            />
          </a>
          <a href={emailUrl} title="email" onClick={() => onIconClick(SOCIAL_PLATFORMS.EMAIL)}>
            <EmailIcon
              className="drop-hint-popup__share-icons__email-icon"
              width="28"
              fill="none"
              stroke="#2D2927"
            />
          </a>
          {smsUrl && (
            <a href={smsUrl} title="sms" onClick={() => onIconClick(SOCIAL_PLATFORMS.SMS)}>
              <SmsIcon
                className="drop-hint-popup__share-icons__sms-icon"
                width="26"
                fill="none"
                stroke="#2D2927"
              />
            </a>
          )}
        </div>
      </div>
    </Popup>
  );
}

DropHintPopup.propTypes = {
  onClose: PropTypes.func,
};

export default DropHintPopup;
